import { render, staticRenderFns } from "./editImgModel.vue?vue&type=template&id=8ed5ea64&scoped=true&"
import script from "./editImgModel.vue?vue&type=script&lang=js&"
export * from "./editImgModel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ed5ea64",
  null
  
)

export default component.exports