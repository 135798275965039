<!--
 * @Author: CL
 * @Date: 2021-06-23 15:27:29
 * @LastEditTime: 2021-06-29 22:11:30
 * @Description: 模态框组件
 *
 * 属性
 * 1. isShow: Boolean    是否展示模态框
 * 2. title: String      模态框的标题
 * 3. width: Number      模态框的宽度
 * 方法 
 * 1. closeModel:  关闭模态框
 * 
-->

<template>
  <div class="dialog-contain" v-show="isShow" @click.stop="">
    <div @click.stop="handleClick" class="dialog-inner" :style="{ width: width + '%'}">
      <div class="inner-title">{{ title }}</div>
      <div class="inner-main">
        <slot></slot>
      </div>
      <!-- <div class="inner-footer">
        <el-button class="my-buttom" v-if="isCancel">{{ cancelText }}</el-button>
        <el-button class="my-buttom" type="primary" v-if="isSure">{{ sureText }}</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //是否显示整个模态框
    isShow: {
      type: Boolean,
      required: true
    },

    //模态框的标题
    title: {
      type: String,
      required: true
    },

    //模态框的宽度
    width: {
      type: Number,
      default: 40
    },

    isClick: {
      type: Boolean,
      default: false
    }

    // //取消按钮的文字
    // cancelText: {
    //   type: String,
    //   default: '取消'
    // },

    // //确认按钮的文字
    // sureText: {
    //   type: String,
    //   default: '确认'
    // },

    // //是否展示取消按钮
    // isCancel: {
    //   type: Boolean,
    //   default: true
    // },

    // //是否展示确认按钮
    // isSure: {
    //   type: Boolean,
    //   default: true
    // }
  },

  methods: {
    closeModel(){
      this.$confirm('您确定要关闭吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.$showMessage({
          type: 'success',
          message: '关闭成功!'
        });
        this.$emit('closeModel', false);
      }).catch(() => {
        this.$showMessage({
          type: 'info',
          message: '已取消关闭'
        });          
      });
    },

    handleClick(){
      if(!this.isClick) return;
      this.$bus.$emit('emitClose');
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.dialog-contain{
  .self-fill(absolute);
  background:rgba(0, 0, 0, 0.7);
  z-index: 999;
  transition: all 1s;
  overflow: scroll;

  .dialog-inner{
    .self-center();
    // height: 90%;
    overflow-y: scroll;
    background: #fff;
    z-index: 9999;
    padding: 20px 40px;
    top: 20%;
    transform: translate(-50%, -20%);
    animation: move .5s forwards;
    border-radius: 4px;

    .inner-title{
      text-align: center;
      font-size: 24px;
      padding: 0 0 20px 0;
      box-sizing: border-box;
    }

    .inner-footer{
      display: flex;
      justify-content: center;
      margin-top: 30px;

      .my-buttom{
        margin: 0 20px;
      }
    }
  }
}

@keyframes move{
  0%{
    top: 20%;
    transform: translate(-50%, -20%);
  }
  100%{
    top: 25%;
    transform: translate(-50%, -25%);
  }
}
</style>
