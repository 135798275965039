<!--
 * @Author: CL
 * @Date: 2021-06-28 11:06:28
 * @LastEditTime: 2021-06-28 11:26:01
 * @Description: 修改图片鉴赏的模态框
-->

<template>
  <div class="editimgmodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="主标题" prop="title">
          <el-input clearable class="cl-dialog" v-model="ruleForm.title" placeholder="必填，请输入主标题"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="subTitle">
          <el-input clearable class="cl-dialog" v-model="ruleForm.subTitle" placeholder="必填，请输入副标题"></el-input>
        </el-form-item>
        <el-form-item label="大图" prop="bigImg">
          <el-upload
            :action="uploadURL"
            :headers="headers"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :limit="1"
            :with-credentials="true"
            ref="upload"
            name="img"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { editImg } from '@/api/banner';

export default {
  data(){
    return {
      ruleForm: {
        title: '',         //主标题
        subTitle: '',      //副标题
        samllImg: '',      //缩略图
        bigImg: '',         //大图
      },

      fileList: [],

      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
        ],
        subTitle: [
          { required: true, message: '副标题不能为空', trigger: 'blur' },
        ],
        bigImg: [
          { required: true, message: '大图不能为空', trigger: 'change' },
        ],
      },

      dialogImageUrl: '',
      dialogVisible: false,
    }
  },

  methods: {
    closeModel(){
      this.close();
    },

    close(data){
      this.$emit('closeEditImgModel', false, data);
      this.$refs['ruleForm'].resetFields();
      this.$refs['upload'].clearFiles();
    },

    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await editImg(this.ruleForm.id, this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: '修改成功'
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    /**
     * 移除图片
     */
    handleRemove() {
      this.$set(this.ruleForm, 'bigImg', '');
      this.$set(this.ruleForm, 'samllImg', '');
      this.fileList = [];
    },

    /**
     * 上传成功的回调
     */
    handleSuccess(res){
      if(res.code == 200){
        this.$set(this.ruleForm, 'bigImg', res.data);
        this.$set(this.ruleForm, 'smallImg', res.data);
      }
    },

    /**
     * 限制上传的个数
     */
    handleExceed(){
      this.$showMessage({
        type: 'warning',
        message: '只能上传一张图片哦'
      })
    },

    /**
     * 预览图片
     */
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    firstTo(val){
      this.ruleForm = this.$utils.deepClone(val);
      if(this.ruleForm.bigImg){
        this.$set(this.fileList, 0, {url: this.baseURL + this.ruleForm.bigImg});
      }else{
        this.fileList = [];
      }
    }
  },

  watch: {
    editInfo: {
      handler(val){
        this.firstTo(val);
      },
      immediate: true
    }
  },

  props: {
    editInfo: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['uploadURL', 'headers', 'baseURL'])
  }
}
</script>

<style lang="less" scoped>

</style>
