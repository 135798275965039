<template>
  <div class="imgback-contain cl-wrap">
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>图片鉴赏信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddImgModel">添加</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="title"
          label="主标题"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="subTitle"
          label="副标题"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addImgModel" 
      title="添加图片鉴赏" 
    >
      <addImg @closeAddImgModel="closeAddImgModel" />
    </Dialog>

    <Dialog 
      :isShow="editImgModel" 
      title="修改图片鉴赏" 
    >
      <editImg 
        :key="key"
        :editInfo="editInfo"
        @closeEditImgModel="closeEditImgModel" 
      />
    </Dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { getBanner, delImg } from '@/api/banner';
import Dialog from '@/components/Dialog';
import addImg from './components/addImgModel.vue';
import editImg from './components/editImgModel.vue';

export default {
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      loading: true,
      addImgModel: false,
      editImgModel: false,
      key: '',
      editInfo: {}
    }
  },

  methods: {
    /**
     * size发生改变
     */
    handleSizeChange(val){
      this.size = val;
    },

    /**
     * page发生改变
     */
    handleCurrentChange(val){
      this.page = val
    },

    /**
     * 打开添加图片鉴赏的模态框
     */
    openAddImgModel(){
      this.addImgModel = true;
    },

    /**
     * 关闭添加图片鉴赏的模态框
     */
    closeAddImgModel(flag, isRefresh){
      this.addImgModel = flag;
      if(isRefresh == 'refresh'){
        this.queryBanner();
      }
    },

    /**
     * 修改
     */
    handleEdit(data){
      this.editInfo = data;
      this.editImgModel = true;
    },

    /**
     * 关闭修改的模态框
     */
    closeEditImgModel(flag, isRefresh){
      this.editImgModel = flag;
      this.key = new Date().getTime();
      if(isRefresh == 'refresh'){
        this.queryBanner();
      }
    },

    /**
     * 删除
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除此图片鉴赏信息吗?').then(async () => {
        const res = await delImg(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryBanner();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * 查询图片鉴赏分页数据
     */
    async queryBanner(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await getBanner(page, size);
        this.loading = false;
        const { count, rows } = res.data;
        this.total = count;
        this.tableData = rows;
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    this.queryBanner();
  },

  components: {
    Pagination,
    Dialog,
    addImg,
    editImg
  }
}
</script>

<style lang="less" scoped>

</style>
