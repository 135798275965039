<!--
 * @Author: CL
 * @Date: 2021-04-07 20:12:52
 * @LastEditTime: 2021-06-23 14:03:36
 * @Description: 分页组件
 * 需要从父组件传递过来的参数
 * page:  当前页
 * size:  每一页的条数
 * total: 总数据量
-->

<template>
  <div class="pagination-page">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="pageSizes"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  },

  methods: {
    /**
     * pageSizes改变时触发, 每页的数据量发生改变
     */
    handleSizeChange(val){
      this.$emit('handleSizeChange', val);
    },

    /**
     * 当前页发生改变时
     */
    handleCurrentChange(val){
      this.$emit('handleCurrentChange', val);
    }
  },

  props: {
    page: {
      type: Number || String,
      default: 1
    },

    size: {
      type: Number || String,
      default: 10,
    },

    total: {
      type: Number || String,
      default: 0
    },

    pageSizes: {
      type: Array,
      default(){
        return [8, 10, 12, 14];
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pagination-page{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px;
  margin-top: 15px;
}
</style>
